<template>
  <div class="Activity__Component">
    <div class="Entity__Details__PageOptions__Back" @click="back()">
      <i><img src="@/assets/down-arrow.svg" alt="" /></i>
      חזור
    </div>
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadQuestion()" :class="{ active: tab === 1 }">
        עריכה
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div v-if="question" class="Activity__Component__TabsContent__Tasks__Content">
            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סטטוס:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="[{ Value: false, Text: 'לא פעיל' }, { Value: true, Text: 'פעיל' }]"
                    v-model="question.status" />
                </b-field>
                <b-field style="width: 100%;" label="מוסד:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAcademicInstitudes" v-model="question.academicInstitutionId" />
                </b-field>
                <b-field style="width: 100%;" label="מחבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getAuthors" v-model="question.authorId" />
                </b-field>
              </div>
            </div>

            <div class="item">
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 100%;" label="סוג שאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true"
                    :options="[{ Value: 1, Text: 'Default' }, { Value: 4, Text: 'טקסט' }, { Value: 5, Text: 'YesNo' }]"
                    v-model="question.exerciseQuestionTypeId" />
                </b-field>
                <b-field style="width: 100%;" label="שם שאלה:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;" v-model="question.name"
                    name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="תיאור קצר:">
                  <input type="text" style="border: 1px solid gray; width: 100%; padding: 14px;"
                    v-model="question.questionDescription" name="" id="">
                </b-field>
                <b-field style="width: 100%;" label="קטגוריות:">
                  <v-select dir="rtl" label="Text" v-model="question.exerciseCategoryIds" :filterable="true"
                    :clearable="true" :reduce="item => item.Value" :getOptionLabel="(item) => `${item.Text}`"
                    :options="categories" :multiple="true" :searchable="true" :close-on-select="false"
                    :placeholder="'בחר ערך מהרשימה'"> </v-select>
                </b-field>
              </div>
            </div>

            <div class="item">
              <b-field label="שאלה">
                <ckeditor :editor="editor" v-model="question.question" :config="editorConfig"></ckeditor>
              </b-field>
            </div>

            <div class="item">
              <b-field label="תשובה מפורטת">
                <ckeditor :editor="editor" v-model="question.detailedAnswer" :config="editorConfig"></ckeditor>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;">
                <b-field style="width: 50%" label="סוג אפקט בשאלה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getEffectTypes" v-model="question.effectId" />
                </b-field>
                <b-checkbox class="bold-text" v-model="question.isHideAnswers">הסתרת חלופות כדיפולט?</b-checkbox>
              </div>
            </div>

            <div class="item">
              <b-field label="נושא שאלה לדוחות ניתוח:">
                <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                  :clearable="true" :optionsAsync="getExerciseSubjects" v-model="question.questionSubjectId" />
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">הגבלת זמן:</span><b-input type="number"
                  v-model="question.timeToRedAlertInSeconds"></b-input>שניות.
                *לאחר זמן מה השעון בתרגול יצבע באדום
              </div>
            </div>

            <div class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                <b-checkbox class="bold-text" v-model="question.isInExam">האם שאלת אמת</b-checkbox>
              </div>
              <FieldInlineDate v-if="question.isInExam" style="width: 30%;" :type="'month'" label="חודש המועד"
                v-model="question.examDate" />
            </div>

            <div class="item">
              <div style="display: flex; align-items: center; margin-bottom: 5px; gap: 5px">
                <span class="bold-text">מספרי פסקאות:</span>
                <b-input type="number" v-model="question.paragraphNumber1"></b-input>
                <b-input type="number" v-model="question.paragraphNumber2"></b-input>
                <b-input type="number" v-model="question.paragraphNumber3"></b-input>
                <b-input type="number" v-model="question.paragraphNumber4"></b-input>
                <b-input type="number" v-model="question.paragraphNumber5"></b-input>
              </div>
            </div>

            <div class="item">
              <b-field label="סעיפים נכונים:">
                <div style="display: flex; align-items: center; gap: 10px;">
                  <FieldInlineSelect @onChange="searchLawClause" :placeholder="'בחר חוק מהרשימה'" :hideHover="true"
                    style="border: 1px solid gray; width: 30%;" :filterable="true" :clearable="true"
                    :reduce="(law) => law" :optionsAsync="getLaws" v-model="question.law" />
                  <FieldInlineSelect v-if="question.law" :hideHover="true" style="border: 1px solid gray; width: 30%;"
                    :filterable="true" :clearable="true" :optionsAsync="getLawClauses" v-model="question.clauseId" />
                  <button class="new" @click="addLawClause" v-if="question.clauseId">הוסף</button>
                </div>
                <div v-if="question.correctClauses && question.correctClauses.length" class="clauses">
                  <div style="display: flex; align-items: center; gap: 10px; margin-top: 10px"
                    v-for="clause, idx in question.correctClauses" :key="idx" class="clause">
                    <div>{{ clause.lawName }} סעיף: {{ clause.clauseId }}</div>
                    <button @click="removeLawClause(clause.clauseId)">X הסרה</button>
                  </div>
                </div>
              </b-field>
            </div>

            <div class="item">
              <div style="display: flex; gap: 10px;" class="bold-text">
                <span>תשובות</span>
                <span @click="isWordAlt = !isWordAlt" style="color: #337ab7; cursor: pointer;">העתק חלופות</span>
              </div>
              <div v-if="isWordAlt" style="display: flex; gap: 10px;">
                <b-input v-model="wordAltText"></b-input>
                <b-button @click="ProcessingTextToFields" :disabled="!wordAltText">טעינת חלופות</b-button>
              </div>
            </div>

            <div v-if="question.answers" v-for="answer, idx in question.answers" :key="idx" class="item">
              <div style="display: flex; align-items: center;" class="bold-text">
                תשובה {{ idx + 1 }}
                <b-checkbox class="bold-text" v-model="answer.isCorrectAnswer">האם נכונה</b-checkbox>
              </div>
              <ckeditor :editor="editor" v-model="answer.answer" :config="editorConfig"></ckeditor>
              <div style="display: flex; gap: 20px;">
                <b-field style="width: 30%;" label="מיומנות:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getMeyumanot" v-model="answer.skill" />
                </b-field>
                <b-field style="width: 30%;" label="חומרת שגיאה:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :options="getSeverities" v-model="answer.severity" />
                </b-field>
                <b-field style="width: 60%;" label="הסבר:">
                  <FieldInlineSelect :hideHover="true" style="border: 1px solid gray; width: 100%;" :filterable="true"
                    :clearable="true" :optionsAsync="getExplanations" v-model="answer.explanationId" />
                </b-field>
              </div>
              <div class="bold-text">
                הערה לתשובה
              </div>
              <ckeditor :editor="editor" v-model="answer.remark" :config="editorConfig"></ckeditor>
            </div>

            <div class="item">
              <b-field label="הערות עורך:">
                <textarea type="text" v-model="question.editorNotes" style="border: 1px solid gray; width: 30%;"
                  rows="4" cols="50" name="" id=""></textarea>
              </b-field>
            </div>

            <div class="question-save-buttons">
              <b-button type="is-info" class="new flex-2" @click="addQuestion">שמירת שינויים</b-button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import vSelect from "vue-select";
import {
  ToastProgrammatic as Toast
} from "buefy";
import "vue-select/src/scss/vue-select.scss";
import CoursesService from "@/services/CoursesService";
import _ from "lodash";
import AdminService from "../services/AdminService";

export default {
  name: "Activity",
  components: {
    vSelect,
    FieldInlineSelect,
    FieldInlineDate,
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    getEffectTypes() {
      return [
        { Value: 0, Text: 'שאלה רגילה' },
        { Value: 1, Text: 'Timer' },
        { Value: 2, Text: 'PopUp' },
        { Value: 3, Text: 'HideAnswers' },
        { Value: 4, Text: 'InterferenceQuestion' },
        { Value: 5, Text: 'PopUpQuestionText' },
      ];
    },
    getSeverities() {
      return [
        { Value: 1, Text: '1' },
        { Value: 2, Text: '2' },
        { Value: 3, Text: '3' },
        { Value: 4, Text: '4' },
        { Value: 5, Text: '5' },
      ];
    },
    lesson() {
      return this.$store.state.lesson;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isWordAlt: false,
      wordAltText: null,
      categories: [],
      isLoading: false,
      question: {
        status: true,
        correctClauses: [],
        answers: [
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
          {
            isCorrectAnswer: false,
            answer: '',
            skill: null,
            severity: null,
            explanationId: null,
            remark: null
          },
        ]
      },
      questionItems: null,
      laws: null,
      verdicts: null,
      newQuestionIds: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'he'
      }
    };
  },
  created() {
    // this.loadQuestion();
    this.getCategories();
  },
  methods: {
    back() {
      this.$router.replace({ path: `/admin/questionsbank` });
    },
    getExplanations() {
      return () =>
        CoursesService.getExplanations().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.answerExplenationData }))
        );
    },
    getMeyumanot() {
      return () =>
        CoursesService.getMeyumanot().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.skillname }))
        );
    },
    ProcessingTextToFields() {
      var sAlltextinpt = this.wordAltText;
      if (sAlltextinpt != '') {
        var arr = sAlltextinpt.split('\t');
        if (arr.length == 5) {

          console.log(arr[0].length);
          console.log(arr[4].length);

          var sA1;
          var sA2;
          var sA3;
          var sA4;

          if (arr[0].length == 2) {
            //v1 - תקין
            sA1 = arr[1].replace("\t", "").replace("ב.", "").trim();
            sA2 = arr[2].replace("\t", "").replace("ג.", "").trim();
            sA3 = arr[3].replace("\t", "").replace("ד.", "").trim();
            sA4 = arr[4].trim();

          }
          else if (arr[4].length == 2) {
            //v2 - טבלה
            sA1 = arr[0].trim();
            sA2 = arr[1].replace("\t", "").replace("א.", "").trim();
            sA3 = arr[2].replace("\t", "").replace("ב.", "").trim();
            sA4 = arr[3].replace("\t", "").replace("ג.", "").trim();
          }


          if (sA1 != '' && sA2 != '' && sA3 != '' && sA4 != '') {
            // var editor4 = CKEDITOR.instances.editor4;
            // editor4.setData(sA1);
            this.question.answers[0].answer = sA1;
            this.question.answers[1].answer = sA2;
            this.question.answers[2].answer = sA3;
            this.question.answers[3].answer = sA4;


            // var editor5 = CKEDITOR.instances.editor5;
            // editor5.setData(sA2);

            // var editor6 = CKEDITOR.instances.editor6;
            // editor6.setData(sA3);

            // var editor7 = CKEDITOR.instances.editor7;
            // editor7.setData(sA4);
          } else {
            Toast.open({
              type: "is-danger",
              message: "שגיאה! יש להעתיק את כל החלופות במלואם כולל האותיות ההתחלתיות!",
              duration: 8000,
            });
          }
        }
      }
    },
    onSearchNewQuestions({ search, loading, setData, setValue }) {
      if (search.length > 3) {
        loading(true);
        return AdminService.getQuestionsInBank({
          Terms: search,
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        })
          .then((r) => {
            if (!r.data.items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.question} - ${i.id}` })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    getCategories() {
      CoursesService.getCategories().then((r) =>
        this.categories = r.data.map((i) => ({ Value: i.id, Text: i.name }))
      );
    },
    getAcademicInstitudes() {
      return () =>
        AdminService.getAcademicInstitudes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getAuthors() {
      return () =>
        AdminService.getAuthors().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    close() {
      this.showNewNote = false;
    },
    getExerciseSubjects() {
      return () =>
        CoursesService.getExerciseSubjects().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.subject }))
        );
    },
    getLaws() {
      return () =>
        CoursesService.getLaws().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLawClauses() {
      return () =>
        CoursesService.getLawClauses(this.question.law.id).then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name })),
        );
    },
    addLawClause() {
      this.question.correctClauses.push({
        lawId: this.question.law.Value,
        lawName: this.question.law.Text,
        clauseId: this.question.clauseId
      });
      this.question.lawId = null;
      this.question.clauseId = null;
    },
    removeLawClause(clauseId) {
      const idx = this.question.correctClauses.findIndex((c) => c.clauseId === clauseId);
      this.question.correctClauses.splice(idx, 1);
    },
    searchLawClause() {
      this.getLawClauses();
    },
    addQuestion() {
      this.isLoading = true;
      AdminService.createQuestion({
        ...this.question
      }).then((r) => {
        this.question = r.data;
        Toast.open({
          type: "is-success",
          message: "העדכון בוצע בהצלחה!",
          duration: 8000,
        });
        this.$router.push('/admin/questionsbank');
      }).catch(() => {
        Toast.open({
          type: "is-danger",
          message: "השיוך נכשל!",
          duration: 8000,
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onChange() { },
    loadQuestion() {
      this.isLoading = true;
      AdminService.getQuestionDetails(this.entityId, {
        LessonIds: [this.entityId]
      }).then((r) => {
        this.question = r.data.items;
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    loadQuestionItems() {
      this.isLoading = true;
      AdminService.getAdminQuestions(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.questionItems = r.data.items;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      AdminService.getAdminLaws(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      AdminService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component {
  background-color: #fff;
}

.Entity__Details__PageOptions__Back {
  border-radius: 3px;
  padding: 10px 25px;
  color: #0091ae;
  position: relative;
  right: -5px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  i {
    width: 11px;
    display: inline-block;
    vertical-align: middle;

    img {
      transform: rotateZ(-90deg);
    }
  }
}

.question-details {
  display: flex;
  margin-top: 20px;
  // justify-content: space-between;
}

.question-detail {
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    width: 350px;
  }
}

.question-instance {
  display: flex;
  align-items: center;
  gap: 10px;
}

.question-buttons {
  display: flex;
  justify-content: space-between;
}

.item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 20px 10px;
  background-color: white;
}

.bold-text {
  font-weight: bold;
}

.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #fff;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;
  margin-top: 15px;

  .columns {
    font-weight: bold;
    background-color: #E98F12;
    text-align: center;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;
  background-color: #eeeeee;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  // cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.question-save-buttons {
  display: flex;
  justify-content: space-around;
  width: 30%;
  gap: 20px;
  margin-top: 15px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
